<template>
  <b-card :title="title">
    <div v-if="members.length">
      <b-table
        :name="`${title}-task-table`"
        :id="`${title}-task-table`"
        striped
        hover
        responsive
        :items="members"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(sms)="data">
          <b-form-checkbox v-if="data.value !== ''" v-model="data.value">
          </b-form-checkbox>
          <div v-else></div>
        </template>
        <template #cell(email)="data">
          <b-form-checkbox v-if="data.value !== ''" v-model="data.value">
          </b-form-checkbox>
          <div v-else></div>
        </template>
      </b-table>
      <hr />
      <b-pagination
        :name="`${title}-task-pagination`"
        v-model="currentPage"
        :total-rows="members.length"
        :per-page="perPage"
        :aria-controls="`${title}-task-table`"
        align="fill"
      ></b-pagination>
    </div>
    <div v-else>
      <div>No data</div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BPagination, BTable, BFormCheckbox } from "bootstrap-vue";
import { getAllMembers } from "./controllers";
export default {
  name: "special-day-tables",
  props: ["special-days", "title"],
  components: {
    BCard,
    BPagination,
    BTable,
    BFormCheckbox,
  },
  data() {
    console.log(this.specialDays);
    return {
      members: [],
      perPage: 5,
      currentPage: 1,
      fields: [
        { key: "index", label: "No." },
        { key: "nickname", label: "Nickname" },
        { key: "first_name", label: "Name" },
        { key: "gender", label: "Gender" },
        { key: "rank", label: "Memder" },
        ...mapDateFields(this),
        { key: "offer", label: "Package Offer" },
        { key: "sms", label: "Send SMS" },
        { key: "email", label: "Send Email" },
      ],
    };
  },
  methods: {

  },
  mounted() {
    const lower = this.specialDays.toString().toLowerCase();
    if (lower === "bd" || lower === "birthday") {
      getAllMembers().then((data) => {
        this.members = data.filter((member) => {
          return member.special_day === "Birthday";
        });
      });
    } else {
      getAllMembers().then((data) => {
        this.members = data.filter((member) => {
          return member.special_day !== "Birthday";
        });
      });
    }
  },
};

function mapDateFields(cls) {
  const lower = cls.specialDays.toString().toLowerCase();
  if (lower === "bd" || lower === "birthday") {
    cls.specialDays = "bd";
    return [{ key: "date", label: "Birth Date" }];
  }
  return [
    { key: "date", label: "Date" },
    { key: "special_day", label: "Special Day" },
  ];
}
</script>
