<template>
  <b-card title="Reservations">
    <div v-if="reservations.length">
      <b-table
        name="reservations-task-table"
        id="reservations-task-table"
        striped
        hover
        responsive
        :items="reservations"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(sms)="data">
          <b-form-checkbox v-if="data.value !== ''" v-model="data.value">
          </b-form-checkbox>
          <div v-else></div>
        </template>
        <template #cell(email)="data">
          <b-form-checkbox v-if="data.value !== ''" v-model="data.value">
          </b-form-checkbox>
          <div v-else></div>
        </template>
        <template #cell(date)="data">
          <span v-if="data.value">{{ dateStringToTime(data.value) }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(table)="data">
          <span v-if="data.value">{{ data.value }}</span>
          <span v-else>-</span>
        </template>
      </b-table>
      <hr />
      <b-pagination
        name="reservations-task-pagination"
        v-model="currentPage"
        :total-rows="reservations.length"
        :per-page="perPage"
        aria-controls="reservations-task-table"
        align="fill"
      ></b-pagination>
    </div>
    <div v-else>
      <div>No data</div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BPagination, BTable, BFormCheckbox } from "bootstrap-vue";
import { getAllReservations } from "./controllers";

export default {
  name: "reservation-task-table",
  components: {
    BCard,
    BPagination,
    BTable,
    BFormCheckbox,
  },
  data() {
    return {
      reservations: [],
      perPage: 5,
      currentPage: 1,
      fields: [
        { key: "index", label: "No." },
        { key: "tel", label: "Phone No." },
        { key: "nickname", label: "Nickname" },
        { key: "first_name", label: "Name" },
        { key: "gender", label: "Gender" },
        { key: "rank", label: "Memder" },
        { key: "seats", label: "seats" },
        { key: "table", label: "Table No." },
        { key: "date", label: "Time" },
        { key: "status", label: "Status" },
      ],
    };
  },
  methods: {
    dateStringToTime(dateStr) {
      if (dateStr) {
        const d = new Date(dateStr).toISOString(); // make sure it's UTC format
        return d.split("T")[1].slice(0, 5);
      }
      return "-";
    },
  },
  mounted() {
    getAllReservations().then((data) => {
      this.reservations = data;
    });
  },
};
</script>
