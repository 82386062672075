import axios from "axios"
export function transformUserMembership(membership) {
  var result = {...membership};
  result.contact_methods.forEach((method) => {
    result[method.toLowerCase()] = true;
  })
  result.contact_methods_str = result.contact_methods?.join(", ")
  result.drinks_str = result.drinks?.join(", ")
  return result;
}

export async function getAllMembers(){
  return new Promise((resolve) => {
    const members = [
      { rank: "platitnum", nickname: "Woosen", prefix_name: "Ms.", first_name: "Viritipa", last_name: "Pakdeeprasong", gender: "Female", age: 42, drinks: ["Gin", "Vodka"], contact_methods: ["SMS", "Email"], offer: "BD - Voucher (Platitnum)", special_day: "Birthday", id: 1},
      { rank: "gold", nickname: "kao", prefix_name: "Ms.", first_name: "Supassra", last_name: "Thanachat", gender: "Female", age: 29, drinks: ["Whisky", "Gin"], contact_methods: ["SMS"], offer: "BD - Voucher (Gold)", special_day: "Birthday", id: 2},
      { rank: "silver", nickname: "Jame", prefix_name: "Mr.", first_name: "Jirayu", last_name: "Tangsrisuk", gender: "Gay", age: 35, drinks: ["Vodka"], contact_methods: ["Email"], offer: "BD - Voucher (Silver)", special_day: "Birthday", id: 3},
      { rank: "platitnum", nickname: "Woosen", prefix_name: "Ms.", first_name: "Viritipa", last_name: "Pakdeeprasong", gender: "Female", age: 42, drinks: ["Gin", "Vodka"], contact_methods: ["SMS", "Email"], offer: "BD - Voucher (Platitnum)", special_day: "Birthday", id: 4},
      { rank: "gold", nickname: "kao", prefix_name: "Ms.", first_name: "Supassra", last_name: "Thanachat", gender: "Female", age: 29, drinks: ["Whisky", "Gin"], contact_methods: ["SMS"], offer: "BD - Voucher (Gold)", special_day: "Birthday", id: 5},
      { rank: "silver", nickname: "Jame", prefix_name: "Mr.", first_name: "Jirayu", last_name: "Tangsrisuk", gender: "Gay", age: 35, drinks: ["Vodka"], contact_methods: ["Email"], offer: "BD - Voucher (Silver)", special_day: "Birthday", id: 6},
      { rank: "platitnum", nickname: "Woosen", prefix_name: "Ms.", first_name: "Viritipa", last_name: "Pakdeeprasong", gender: "Female", age: 42, drinks: ["Gin", "Vodka"], contact_methods: ["SMS", "Email"], offer: "BD - Voucher (Platitnum)", special_day: "Anniversary", id: 7},
      { rank: "gold", nickname: "kao", prefix_name: "Ms.", first_name: "Supassra", last_name: "Thanachat", gender: "Female", age: 29, drinks: ["Whisky", "Gin"], contact_methods: ["SMS"], offer: "BD - Voucher (Gold)", special_day: "First Met", id: 8},
      { rank: "silver", nickname: "Jame", prefix_name: "Mr.", first_name: "Jirayu", last_name: "Tangsrisuk", gender: "Gay", age: 35, drinks: ["Vodka"], contact_methods: ["Email"], offer: "BD - Voucher (Silver)", special_day: "First Met", id: 9},
      { rank: "platitnum", nickname: "Woosen", prefix_name: "Ms.", first_name: "Viritipa", last_name: "Pakdeeprasong", gender: "Female", age: 42, drinks: ["Gin", "Vodka"], contact_methods: ["SMS", "Email"], offer: "BD - Voucher (Platitnum)", special_day: "Anniversary", id: 10},
      { rank: "gold", nickname: "kao", prefix_name: "Ms.", first_name: "Supassra", last_name: "Thanachat", gender: "Female", age: 29, drinks: ["Whisky", "Gin"], contact_methods: ["SMS"], offer: "BD - Voucher (Gold)", special_day: "Pets Birthday", id: 11},
      { rank: "silver", nickname: "Jame", prefix_name: "Mr.", first_name: "Jirayu", last_name: "Tangsrisuk", gender: "Gay", age: 35, drinks: ["Vodka"], contact_methods: ["Email"], offer: "BD - Voucher (Silver)", special_day: "Anniversary", id: 12},
    ]
    resolve(members.map(transformUserMembership))
  })
  const { data } = await axios.get('/getMembers')
  return data
}

export async function getAllReservations(){
  return new Promise((resolve) => {
    const members = [
      { rank: "platitnum", nickname: "Woosen", prefix_name: "Ms.", first_name: "Viritipa", last_name: "Pakdeeprasong", gender: "Female", age: 42, drinks: ["Gin", "Vodka"], contact_methods: ["SMS", "Email"], seats: 4, table: "A1", date: "2022-10-27T19:00:00", status: "Confirmed", id: 1},
      { rank: "gold", nickname: "kao", prefix_name: "Ms.", first_name: "Supassra", last_name: "Thanachat", gender: "Female", age: 29, drinks: ["Whisky", "Gin"], contact_methods: ["SMS"], seats: 2, table: "B1", date: "2022-10-27T19:00:00", status: "Reserved", id: 2},
      { rank: "silver", nickname: "Jame", prefix_name: "Mr.", first_name: "Jirayu", last_name: "Tangsrisuk", gender: "Gay", age: 35, drinks: ["Vodka"], contact_methods: ["Email"], seats: 5, table: "A2", date: "2022-10-27T19:00:00", status: "Confirmed", id: 3},
      { rank: "platitnum", nickname: "Woosen", prefix_name: "Ms.", first_name: "Viritipa", last_name: "Pakdeeprasong", gender: "Female", age: 42, drinks: ["Gin", "Vodka"], contact_methods: ["SMS", "Email"], seats: 10, table: "B2", date: "2022-10-27T19:00:00", status: "Reserved", id: 4},
      { rank: "gold", nickname: "kao", prefix_name: "Ms.", first_name: "Supassra", last_name: "Thanachat", gender: "Female", age: 29, drinks: ["Whisky", "Gin"], contact_methods: ["SMS"], seats: 2, date: "2022-10-27T19:00:00", status: "Waiting List", id: 5},
      { rank: "silver", nickname: "Jame", prefix_name: "Mr.", first_name: "Jirayu", last_name: "Tangsrisuk", gender: "Gay", age: 35, drinks: ["Vodka"], contact_methods: ["Email"], seats: 6, date: "2022-10-27T19:00:00", status: "Waiting List", id: 6},
      { rank: "silver", nickname: "Jame", prefix_name: "Mr.", first_name: "Jirayu", last_name: "Tangsrisuk", gender: "Gay", age: 35, drinks: ["Vodka"], contact_methods: ["Email"], seats: 6, status: "Waiting List", id: 7},
    ]
    resolve(members.map(transformUserMembership))
  })
  const { data } = await axios.get('/getMembers')
  return data
}