<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <event-calendar
            :events="events"
            :eventClick="calendarEventClick"
            :dateClick="calendarDateClick"
            :selectedEvent="null"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col><event-upcoming :events="events" /></b-col>
      </b-row>
    </b-col>
    <b-col>
      <b-row>
        <b-col>
          <reservation />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <special-day special-days="db" title="Birthdays" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <special-day special-days="sp" title="Special Days" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BButton, BCol, BRow, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// eslint-disable-next-line import/no-cycle
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { EventCalendar, EventUpcoming } from "@views/event-component";
import { Reservation, SpecialDay } from "./";
import { getEvents } from "@views/event-component/utils";
export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    EventCalendar,
    EventUpcoming,
    Reservation,
    SpecialDay
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isVerticalMenuCollapsed:
        this.$store.state.verticalMenu.isVerticalMenuCollapsed,
      events: [],
    };
  },
  created() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },
  destroyed() {
    this.$store.commit(
      "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
      this.isVerticalMenuCollapsed
    );
  },
  methods: {
    calendarEventClick(event) {
      console.log(event, this.events);
    },
    calendarDateClick(date) {
      console.log(date);
    },
  },
  mounted() {
    getEvents().then((data) => {
      this.events = data;
    });
  },
};
</script>
